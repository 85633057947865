import { graphql } from 'gatsby'
import { HomeScreen } from '@src/components/screens/HomeScreen'

export default HomeScreen

export const pageQuery = graphql`
  query EnIndexQuery {
    allServicesYaml(filter: { locale: { eq: "en" } }) {
      edges {
        node {
          icon {
            publicURL
          }
          title
          description
        }
      }
    }
  }
`
