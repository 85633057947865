import Box from '@src/components/atoms/Box'
import ContactBelt from '@src/components/molecules/ContactBelt'
import React from 'react'

import '@src/theme/home.sass'

const bgimage = require('@src/images/bg-home-top.png')

export const HomeScreen: React.FC<any> = (props) => {
  const items = props.data.allServicesYaml.edges
  return (
    <div>
      <Catch />
      <Box>
        <Services items={items} />
      </Box>
      <ContactBelt />
    </div>
  )
}

const Catch = () => (
  <div
    className="homeCatch"
    style={{
      backgroundImage: `url(${bgimage})`,
      backgroundSize: '100% auto',
      backgroundPosition: 'center',
      width: '100vw',
      maxHeight: `${76700 / 1440}vw`,
    }}
  >
    <h1>
      {'We develop\nsomething nice'.split('\n').map((t, index) => (
        <p key={index}>{t}</p>
      ))}
    </h1>
  </div>
)

// const OurStack: React.FC<any> = (props) => {}
const Services: React.FC<any> = (props) => (
  <div className="homeService">
    <h2 className="heading">Our Services</h2>
    <p className="description">
      We are building great services to make our world better.
      <br />
    </p>
    <div className="services">
      {props.items.map(({ node }: any) => (
        <div className="serviceBox" key={node.title}>
          <div className="icon">
            <img src={node.icon.publicURL} />
          </div>
          <div className="title" dangerouslySetInnerHTML={{ __html: node.title }} />
          <div className="description" dangerouslySetInnerHTML={{ __html: node.description }} />
        </div>
      ))}
    </div>
  </div>
)

// const Belt = ({ children }) => (
//   <div
//     style={{
//       backgroundColor: '#FAFAFA',
//       paddingTop: 80,
//       paddingBottom: 80,
//       textAlign: 'center',
//       // marginLeft: '-10vw',
//       // marginRight: '-10vw',
//       marginTop: 80,
//     }}
//   >
//     {children}
//   </div>
// )
// const Button = (props:any) => (
//   <div style={{ ...props.style }} className="btn">
//     <Link to={props.to}>{props.text}</Link>
//   </div>
// )
// const Flex = ({ children, style }) => (
//   <div style={{ display: 'flex', ...style }}>{children}</div>
// )
